.header {
    font-family: Ubuntu, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;

    /* Gradient */
    background: radial-gradient(50% 88.89% at 50% 44.72%, #4A96C4 0%, #3973A1 100%);
}


.header-control {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.btn {
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}

.header__buttons-btn {
	width: 42px;
	height: 42px;
	border: 1px solid rgba(255,255,255,0);
	box-sizing: border-box;
	border-radius: 50%;
	
	-ms-flex-pack: center;
	justify-content: center;
	transition: .3s;
    cursor: pointer;
    display: flex;
    align-items: center;
    fill: white;
    transition: all .3s;
}



.header__buttons-btn:hover{
    background-color: #fdd83b;
    fill: #4285b3;
    stroke: #4285b3;
   
}

.fa-eye::before {
    content: url(./img/eye.svg);
    height: 20px;
    width: 100%;
    background: #ff3a44;    
}

.fa-bell:before {
    content: url(./img/bell.svg);
    height: 20px;
    width: 100%;
}

.fa-search:before {
    content: url(./img/search.svg);
}

.hc-link-notice {
    width: 18px;
    height: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: -10px;
    right: -9px;
    background: #ff3a44;
    color: #fff;
    font-size: 11px;
    line-height: 11px;
    font-weight: 700;
    border-radius: 50%;
}

.eye-nazk-block {
    display: contents;
    font-size: 18px;
    font-weight: 400;    
}

.nazk-lang a {
    text-decoration: none;
    color: white;
    font-size: 15px;
    margin-right: 15px;
}

.eye-nazk {
    fill: inherit;
}

.hc-link {
    position: relative;
    color: #fff;
    text-decoration: none;
    background: 0 0;
    border: none;
}

.hc-news {
    display: none;
}

.hc-search {
    display: none;
}

.header_top {
        /* Rectangle 281 */
        left: 0px;
        top: 0px;
        margin: auto;
}

.header-grid {
    display: grid;
    grid-template-columns: 33% 33% 33%;
}

.header_name_grid {
    display: grid;
    grid-template-columns: 140px 20px 60px;
}

.header_button_bloc {
    display: grid;
    grid-template-columns: 30px 90px 80px;
}

.menu_bloc {
    display: flex;
    align-items: center;
}

.menu_bloc #t_menu {
    display: none;
}

@media screen and (min-width: 768px){
    .menu_bloc #t_menu {
        display: initial;
    }
}

.menu_bloc span {
    margin-left: 10px;
    font-size: 20px;
}

.logo_div {
    /* Line 27 */
    width: 55px;
    height: 0px;
    left: 103px;
    top: 5px;

    border: 0.5px solid #FFFFFF;
    transform: rotate(90deg);
}

.header_name_portal div {
    background-color: #FFFFFF;
    color: black;
}

.menu-open {
    position: absolute;
    z-index: 100;
    left:0px;
    width: 100%
}

.header__burger-btn {
	width: 42px;
	height: 42px;
	/*width: 56px; */
	/* height: 56px; */
	/* background: rgba(255,255,255,.1); */
	/* border: 1px solid #FFFFFF; */
	border: 1px solid rgba(255,255,255,.5);
	box-sizing: border-box;
	border-radius: 50%;
	position: relative;
	/* cursor: pointer; */
	transition: .3s;
}

.header__burger-btn span {
	position: absolute;
	top: 11px;
	left: 10px;
	transform: translateX(-50%);
	width: 23px;
	height: 3px;
	background: #FFFFFF;
	border-radius: 2px;
	transition: .3s;
}

.header__burger-btn span:nth-child(2) {
	top: 18px;
}

.header__burger-btn span:last-child {
	top: 25px;
}

.header__burger-btn:hover {
	background: #FFD72F;
    border: 1px solid #FFD72F;
    cursor: pointer;
}

.header__burger-btn:hover span{
    background: #37A4E5;
}

.header__burger-btn.active span {
	z-index: 5;
	top: 19px;
	transform: translateX(-47%) rotate(-45deg);
}

.header__burger-btn.active span:nth-child(2) {
	display: none;
}

.header__burger-btn.active span:last-child {
	top: 19px;
	transform: translateX(-47%) rotate(45deg);
}

.data_notify .header__notifications{
    
    padding:10px;
    float: right;
}

@media screen and (min-width: 768px) {
    .data_notify {
        margin-left: 10px;
        z-index: 20; 
    }
}

.data_notify .header__notifications .icon.white svg{
    fill:#fff;opacity:1;width:24px;height:24px
}
.data_notify .header__notifications{margin-right:0;margin-top:9px}
.data_notify .header__notifications.active{margin-top:9px}
.header__nofitications-dropdown .tooltip-el{display:none}
.data_notify .tab_button{justify-content:center;cursor:auto}
.data_notify .tab.active{border-bottom:none;color:#464646}
.data_notify .cnt{display:none}
.header__nofitications-dropdown .title{text-decoration: none}