

.head_prerow {
	height: 36px;
    position: relative;
    max-width: 1260px;
	width: 100%;
	margin: auto;
    padding: 0 70px;
    font-weight: 300;
}

.head_prerow a{
    text-decoration: none;
}

.head_prerow ul {
	list-style: none;
	margin: 0;
	padding: 0;
	padding-top: 10px;
	display: flex;
}

.head_prerow li:first-child {
	padding-right: 10px;
}

.head_prerow li {
	position: relative;
	line-height: 25px;
	flex-wrap: wrap;
}

.head_prerow li+li{padding:0 6px}
.head_prerow li a{letter-spacing: -0.3px;color:rgba(231,246,255,0.8);font-size:14px;position:relative;display: block;white-space: nowrap;transition: .3s;}
.head_prerow li a:hover{color:#FFD72F}
.head_prerow li.active a{color:#FFD72F;padding-bottom:3px;border-bottom:1px solid #FFD72F}
.head_prerow li:not(.active) a:after{-webkit-transform:translateX(-50%);-ms-transform:translateX(-50%);-webkit-transition:.5s;-o-transition:.5s;position:absolute;bottom:-4px;left:50%;width:0;height:1px;transform:translateX(-50%);background-color:#FFD300;content:'';transition:.5s}
.head_prerow li:not(.active) a:hover:after{width:100%}
.head_prerow a.disabled_text{white-space: nowrap;color:#FFF;display:block;text-align:center;transition:.5s;position:absolute;opacity:0;top:0;color:#c4c4c4;transform:translateX(-50%);left:50%;}
.head_prerow .disabled:hover .disabled_text{opacity:1;transform:translateY(0%);    transform: translateX(-50%);}
.head_prerow .disabled:hover a{opacity:0;transform:translateY(-100%)}
@media only screen and (max-width : 1200px) {
    .head_prerow {
        display: none;
    }
    .header.fixed-h~main{
        padding-top: 100px;
    }
}