.portach {
    padding: 50px 0;
  }
  @media (max-width: 768px) {
    .portach {
      padding: 40px 0;
    }
  }
  .portach-body {
    padding-top: 65px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  @media (max-width: 768px) {
    .portach-body {
      padding-top: 24px;
      flex-direction: column;
    }
  }
  .portach-progress {
    width: calc((100% / 7) * 5);
    height: 1px;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% / 2.4);
  }
  @media (max-width: 768px) {
    .portach-progress {
      width: 1px;
      height: calc(100% - 100px);
      top: 100px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  .portach-progress .progr-main {
    border: 1px dashed rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
  @media (max-width: 768px) {
    .portach-progress .progr-main {
      height: 100%;
    }
  }
  .portach-progress .progr-green {
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 2;
    border: 1px dashed #15CF74;
    background: #F2F9FD;
    animation-duration: 20s;
    animation-name: slidein;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  @media (max-width: 768px) {
    .portach-progress .progr-green {
      width: 1px;
      height: 100%;
    }
  }
  .portach-progress .progr-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    animation-duration: 20s;
    animation-name: slidein_circle;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  @media (min-width: 768.1px) {
    .portach-progress .progr-circle {
      top: 0px;
      right: 0;
    }
  }
  @media (max-width: 768px) {
    .portach-progress .progr-circle {
      bottom: 0;
      right: 0;
    }
  }
  .portach-progress .progr-circle .circle {
    width: 0;
    height: 0;
    background: #15CF74;
    border-radius: 50%;
    position: absolute;
    opacity: 0;
    animation: blop 2.5s ease-out infinite;
    opacity: 0.6;
  }
  .portach-progress .progr-circle .circle:nth-child(2) {
    animation-delay: 0.6s;
    opacity: 0.9;
  }
  .portach-item {
    flex: 1;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    text-align: center;
    z-index: 2;
  }
  @media (max-width: 768px) {
    .portach-item {
      margin-bottom: 96px;
      padding-top: 10px;
      padding-bottom: 10px;
      background: #F2F9FD;
    }
    .portach-item:last-child {
      margin-bottom: 0;
    }
  }
  .portach-item:last-child .portach-item--img:after {
    display: none;
  }
  .portach-item--img {
    padding: 0 5px;
    position: relative;
  }
  @media (max-width: 768px) {
    .portach-item--img {
      width: 50px;
      height: 50px;
    }
  }
  .portach-item--img img {
    max-width: 70px;
  }
  @media (max-width: 768px) {
    .portach-item--img img {
      width: 100%;
    }
  }
  .portach-item--caption {
    padding-top: 25px;
    color: #595959;
    font-size: 16px;
    line-height: 24px;
  }
  @media (max-width: 768px) {
    .portach-item--caption {
      max-width: 250px;
      padding-top: 16px;
      font-size: 14px;
      line-height: 21px;
    }
  }
  
  @media (min-width: 768.1px) {
    @keyframes slidein {
      from {
        width: 0%;
      }
      to {
        width: 100%;
      }
    }
    @keyframes slidein_circle {
      0% {
        right: 100%;
      }
      99% {
        right: 0;
      }
      100% {
        right: 100%;
      }
    }
  }
  @media (max-width: 768px) {
    @keyframes slidein {
      from {
        height: 0%;
      }
      to {
        height: 100%;
      }
    }
    @keyframes slidein_circle {
      0% {
        bottom: 100%;
      }
      99% {
        bottom: 0;
      }
      100% {
        bottom: 100%;
      }
    }
  }
  @keyframes blop {
    50% {
      width: 20px;
      height: 20px;
    }
  }