.attractor-item--download {
    bottom: 8px;
    position: absolute;
    left: 25%;
    display: flex;
    padding: 7px;
    display: none;
}

.attractor-body {
    text-align: center;
}

.attractor-item a {
    text-decoration: none;
}

.attractor-item--download:hover {
    background-color: #BEE6FD;
    border-radius: 8px;
}

.attractor-item:hover {
    box-shadow: 0px 2px 8px rgba(92, 99, 105, 0.15);
}