.sect-map {
  padding: 85px;
}

.map-cont {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

@media (max-width:768px) {
  .map-cont {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
  }
  .map-item {
    max-width: 100%
  }
  .map-item svg {
    width: 360px;
    height: 240px
  }
  .map-info {
    padding-top: 35px;
    padding-left: 15px
  }
}

.map-item path {
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  cursor: pointer
}

.map-item path:hover {
  fill: #007cba
}

.map-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column
}

.map-info--item {
  margin-bottom: 16px;
  padding-bottom: 17px;
  border-bottom: 1px solid rgba(51, 51, 51, .1)
}

.map-info--list {
  padding-top: 16px;
  font-size: 18px;
  font-weight: 400;
  color: #595959
}

.map-info--title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: #212121
}

.map-info--li {
  padding-bottom: 12px;
  position: relative
}

.map-info--li:nth-child(1):before {
  background: #fcda4b
}

.map-info--li:nth-child(2):before {
  background: #15cf74
}

.map-info--li:last-child {
  padding-bottom: 0
}

.map-info--li:before {
  content: '';
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  left: -20px;
  top: 5px;
  border-radius: 50%;
  background: #adadad
}