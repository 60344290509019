.charts {
    padding: 5px;
}

div.google-visualization-tooltip {

    padding: 0 !important;
    margin: 0 !important;
    border:none !important;
    box-shadow: unset !important;
    background-color: rgba(0,0,0,0) !important;
    height:auto !important;
    overflow:hidden !important;

}

.title_chart_result {
    text-align: center;
    font-size: 22px;
    padding-top: 20px;
    font-weight: bold;
    padding-bottom: 20px;
}

.title_chart_name {
    text-align: left;
    font-size: 24px;
    padding-bottom: 40px;
    font-weight: bold;
}

svg > g > g.google-visualization-tooltip { pointer-events: none }
