.bottom-header-link {
    text-decoration: none;
    color: none;
}


.info_banner-info--item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.info_banner-info--title {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(255,255,255,.75);
}

.info_banner-info--num {
    font-size: 32px;
    font-weight: 500;
    color: white;
    vertical-align: -40%;
}

.info_banner-info--plus {
    margin-left: 5px;
    padding: 2px;
    font-size: 17px;
    font-weight: 500;
    color:#00ff82;
    background: rgba(21,207,116,.1);
    border-radius: 2px 4px;
}

.info_banner-info--last {
    margin-left: 3px;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
}

.bottom-header {
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    padding-top: 35px;
    padding-bottom: 35px;
    display: none;
}

.bottom-header :hover .info_banner-info--num{
    color: #fcda4b
}

.bottom-header :hover .info_banner-info--title {
    color: white;
}

.bottom-header :hover {
    cursor: pointer;
}

@media screen and (min-width: 768px) {
    .bottom-header {
        display: flex;
    }
}

.info_banner-info--body {
    margin-top: 5px;

}


