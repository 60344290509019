.info_banner {
	 position: relative;
	 background: radial-gradient(50% 88.89% at 50% 44.72%, #4a96c4 0, #3973a1 100%);
	 z-index: 1;
}
 .info_banner-imgs {
	 height: 200px;
	 position: absolute;
	 right: 0;
	 top: calc(50% - (200px / 2));
	 display: flex;
	 flex-direction: row;
	 justify-content: flex-end;
}
 .info_banner-imgs .ibi-1 {
	 animation-name: ibi1;
	 animation-duration: 4.8s;
	 position: absolute;
	 right: 480px;
}
 .info_banner-imgs .ibi-2 {
	 animation-name: ibi2;
	 animation-duration: 5.3s;
	 position: absolute;
	 right: 360px;
}
 .info_banner-imgs .ibi-3 {
	 animation-name: ibi3;
	 animation-duration: 6s;
	 position: absolute;
	 right: 240px;
}
 .info_banner-imgs .ibi-4 {
	 animation-name: ibi4;
	 animation-duration: 6.5s;
	 position: absolute;
	 right: 120px;
}
 .info_banner-imgs .ibi-5 {
	 animation-name: ibi5;
	 animation-duration: 10s;
	 position: absolute;
	 right: 0;
}
 .info_banner-cont {
	 padding: 150px 0 50px;
}
 .info_banner-title {
	 max-width: 50%;
	 color: #fff;
}
 .info_banner-subtitle {
	 max-width: 50%;
	 padding-top: 15px;
	 color: rgba(255, 255, 255, .9);
}
 .info_banner-btn {
	 padding-top: 25px;
}
 .info_banner-bottom {
	 padding: 30px 0 40px;
	 border-top: 1px solid rgba(255, 255, 255, .4);
}
 .info_banner-info {
	 display: flex;
	 flex-direction: row;
	 justify-content: space-between;
}
 .info_banner-info--item {
	 display: flex;
	 flex-direction: column;
}
 .info_banner-info--title {
	 margin-bottom: 8px;
	 font-size: 14px;
	 font-weight: 400;
	 color: rgba(255, 255, 255, .75);
}
 .info_banner-info--num {
	 font-size: 28px;
	 font-weight: 500;
	 color: rgba(255, 255, 255, .9);
}
 .info_banner-info--plus {
	 margin-left: 5px;
	 padding: 2px;
	 font-size: 14px;
	 font-weight: 500;
	 color: #15cf74;
	 background: rgba(21, 207, 116, .1);
	 border-radius: 2px 4px;
}
 .info_banner-info--last {
	 margin-left: 3px;
	 font-size: 14px;
	 font-weight: 400;
	 color: #fff;
}
 @keyframes ibi1 {
	 0% {
		 opacity: 0;
	}
	 20% {
		 opacity: 0;
	}
	 100% {
		 opacity: 1;
		 margin: 0;
	}
}
 @keyframes ibi2 {
	 0% {
		 opacity: 0;
	}
	 40% {
		 opacity: 0;
		 right: 380px;
	}
	 100% {
		 opacity: 1;
	}
}
 @keyframes ibi3 {
	 0% {
		 opacity: 0;
	}
	 60% {
		 right: 260px;
		 opacity: 0;
	}
	 100% {
		 opacity: 1;
	}
}
 @keyframes ibi4 {
	 0% {
		 opacity: 0;
	}
	 80% {
		 right: 140px;
		 opacity: 0;
	}
	 100% {
		 opacity: 1;
	}
}
 @keyframes ibi5 {
	 0% {
		 opacity: 0;
	}
	 70% {
		 opacity: 0;
	}
	 100% {
		 opacity: 1;
	}
}
 
