.result-find {
    padding: 10px 10px;
    border-top: 1px solid lightgray;
    margin-top: 30px;
}

.item-info {
    padding: 10px 0px; 
}

.item-label {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
}

.item-value {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    display: inline;
    
}

.item-color-pr {
    background-color: #FF3A44;
    padding: 5px;
}

.item-color-info {
    background-color: #FCDA4B;
    padding: 5px;
}

.item-color-ok {
    background-color: #15CF74;
    padding: 5px;
}

.label-info {
    max-width: 202px;
    max-height: 56px;

    background: #FFF4D7;
    border-radius: 2px;

    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #595959;
    padding: 4px 8px 4px 8px;
    margin-top: 10px;
}

.notes-titul {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
}

.item-info li {
    margin-top: 10px;
    font-size: 12px;
    line-height: 1.5;
}

.res-download {
    position: relative;
    text-decoration: none;
    background: 0 0;
    border: none;
    margin: 10px 0px 0px 0px;
    cursor: pointer;
}

.res-download:before {
    content: url(./img/paperclip.svg);
}

.res-download-text {
    margin-left: 10px;
    vertical-align: super;
    color: #007CBA;
    font-size: 17px;
    cursor: pointer;
}

.res-download-title {
    margin-left: 40px;
}

.res-download-type {
    margin-left: 40px;
    color: #989898;
    font-size: 14px;
    margin-top: 5px;
}

.detail-label {
    padding-top: 20px;
    font-size: 17px;
}

.detail-data {
    padding-top: 20px;
    font-size: 17px;
    font-weight: 700;
}

.detail-header {
    padding-top: 20px;
    padding-bottom: 20px;
}

.detail-header-label {
    font-size: 17px;
    font-weight: 700;
}

.detail-header-button {
    padding-left: 20px;
    font-size: 17px;
    color: #007CBA;
    cursor: pointer;
}

.detail-header-button:hover {
    color: #FCDA4B;
}