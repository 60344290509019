.central_bloc {
    display: grid;
    grid-template-columns: 33% 67%;
}

.main_text_central {
    font-weight: bold;
    font-size: 42px;
    line-height: 48px;
}

.central_info {
    margin-top: 15px;
    line-height: 1.5;
    font-size: 17px;
    margin-right: 37px;
    font-weight: 400;
}

.central_info a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    z-index: 100;
}

.central_info a:hover {
    color: #FFD72F;
}

.central_button {
    margin-top: 25px;
    width: 130%;
}

.central-m {
    max-width: 1280px;
}

.central-m::after {
    background: url('./img/step2.svg');
}

.central-action {
    max-width: 75%;
}

@media screen and (min-width: 768px) {
    .central-action {
        max-width: 45%;
    }
}