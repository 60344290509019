.sh-title {
    position: relative;
    font-weight: 700;
    color: #212121;
    font-size: 35px
}
.sh-title:before {
    content: '';
    width: 8px;
    height: 100%;
    display: block;
    position: absolute;
    left: -20px;
    border-radius: 6px;
    background: #fcda4b;
}
.ico_acord_slide {
    width: 24px;
    height: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #f2f9fd;
    border-radius: 5px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.ico_acord_slide:before {
    content: '';
    width: 16px;
    height: 2px;
    position: absolute;
    display: block;
    background: #007cba;
    border-radius: 5px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.ico_acord_slide:after {
    content: '';
    width: 16px;
    height: 2px;
    position: absolute;
    display: block;
    background: #007cba;
    border-radius: 5px;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.ico_acord_slide.active:after {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
}
.quest .accordion {
    padding-top: 45px;
}

.quest {
    display: flex;
    max-width: 1280px;
    margin: auto;
}

.quest a {
    display: flex;
    margin-right: 35px; 
}
@media (max-width:768px) {
    .quest .accordion {
    width: 100%}
}.accordion-item {
    border-top: 1px solid #d4d4d4;
    
}
.accordion-item:last-child {
    border-top: 1px solid #d4d4d4;
    border-bottom: 1px solid #d4d4d4;
}
.accordion-btn {
    padding: 26px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    cursor: pointer;
}
.accordion--title {
    color: #595959;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
}
.accordion-slide {
    overflow: hidden;
    background: #f2f9fd;
    max-height: 1000px;
    transition: max-height 1s ease-in-out;
}

.accordion-slide-main {
    padding: 25px 25px 25px 45px;
}

.accordion-slide[aria-expanded="false"] {
    max-height: 0px;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}
.accordion-slide--title {
    margin-bottom: 12px;
    display: inline-block;
    color: #595959;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}
.accordion-slide--caption {
    margin-top: 15px;
    display: inline-block;
    color: #595959;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}
.accordion-slide--list {
    list-style: none;
    line-height: 24px;
}
.accordion-slide--list li {
    padding-bottom: 8px;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}
.accordion-slide--list li:last-child {
    padding-bottom: 0;
}
.accordion-slide--list li:before {
    content: '';
    width: 6px;
    height: 6px;
    display: block;
    position: absolute;
    top: 9px;
    left: -20px;
    border-radius: 50%;
    background: #007cba;
}
