.anketa-form fieldset{
    border: none;
}


.form-group.field.field-string{
    display: grid;
    margin-top: 24px;
}


.form-group.field.field-string label{
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}

.form-group.field.field-string input[type="text"]{
    min-height: 46px;
    max-width: 352px;
    margin-top: 8px;

}

.form-group.field.field-string input[type="radio"]{
    border: 0px;
    width: 100%;
    height: 20px;
}

.MuiDivider-root {
    display: none
}

.ql-editor {
    min-height: 200px;
}

.MuiStepper-root {
    background-color: #F2F9FD;
}

.MuiAutocomplete-clearIndicator[title="Прибрати обрані"] {
    display: none;
}