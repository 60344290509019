.faq-banner-body {

    position: relative;
    width: 328px;
    height: 484px;
    background: #007CBA;
    border-radius: 16px;

    top: 139px;
    margin-right: 75px;

}

.faq-banner-main {
    padding: 32px 32px 32px;
    line-height: 1.5;
    color: white;
}

.faq-banner-main--head {
    font-weight: 700;
    font-size: 20px;
}

.faq-banner-main--text {
    margin-top: 20px;
}

.faq-banner-main--text ul {
    line-height: 2.0;
    padding: 0px 0px 0px 15px;
}

.faq-banner-main--button a {
	width: 236px;
	height: 46px;
	border-radius: 8px;
	justify-content: center;
	padding: 10px;
	text-decoration: none;
	color: #007cba;
	background-color: #FCDA4B;
	font-size: 16px;
    font-weight: 500;
}

.faq-banner-main--button a:hover {
    background: #BEE6FD;
    color: black;
}

